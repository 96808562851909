import React from 'react';
import classNames from 'classnames';

import Button from '@components/Button';
import Typography from '@components/Typography';
import links from '@constants/links';

import { UnitsIcon, CancelIcon, CheckIcon, DSEIcon } from './assets/icons';
import './Plan.scss';

type VariantType = 'primary' | 'secondary' | 'tertiary';

interface Feature {
  active: boolean;
  text: string;
  description?: string;
  key: string;
}

interface Props {
  title: string;
  description: string;
  units: string;
  dse?: string;
  features: {
    title: string;
    features: Feature[];
  }[];
  variant: VariantType;
  buttonText: string;
}

const FeatureContainer: React.FC<Feature> = ({ key, active, text, description }) => (
  <React.Fragment key={key}>
    <div className="TDB-Plan__feature-container">
      {active ? (
        <div className="TDB-Plan__check-icon-wrapper">
          <CheckIcon />
        </div>
      ) : (
        <CancelIcon />
      )}
      <div className="TDB-Plan__feature-text">
        {text} <span className="TDB-Plan__feature-description">{description}</span>
      </div>
    </div>
  </React.Fragment>
);

const Plan: React.FC<Props> = ({ title, description, units, dse, features, variant, buttonText }) => (
  <div className="TDB-Plan-container">
    <div className={`TDB-Plan TDB-Plan-gradient--${variant}`}>
      <div className={`TDB-Plan-gradient-s-${variant}`}>
        <Typography
          as="h5"
          fontSize="heading-4"
          fontWeight="semi-bold"
          color="neutral-600"
          className={`TDB-Plan__title TDB-Plan__title--${variant}`}
        >
          {title}
        </Typography>
        <Typography className="TDB-Plan__description" as="p" fontSize="body" fontWeight="medium" color="neutral-600">
          {description}
        </Typography>
        <div className={`TDB-Plan__infobox TDB-Plan__infobox--${variant}`}>
          <UnitsIcon />
          <Typography fontSize="body" fontWeight="medium" color="neutral-900">
            {units}
          </Typography>
        </div>

        {dse ? (
          <>
            <div className={`TDB-Plan__infobox TDB-Plan__infobox--top-margin TDB-Plan__infobox--${variant}`}>
              <DSEIcon />
              <Typography fontSize="body" fontWeight="medium" color="neutral-900">
                {dse}
              </Typography>
            </div>
          </>
        ) : null}

        <div className="TDB-Plan__divider TDB-Plan__divider--top-margin" />

        <div className="TDB-Plan__divider TDB-Plan__divider--bottom-margin" />

        <div className="TDB-Plan__footer">
          <Button block primary className={`TDB-Plan__footer__button-${variant}`} to={links.contactSales}>
            {buttonText}
          </Button>
        </div>
        <div className="TDB-Plan__main">
          <div className="TDB-Plan__features">
            {features.map((feature) => (
              <div
                key={feature.title}
                className={classNames('TDB-Plan__feature', {
                  // 'TDB-Plan__feature--active': feature.active,
                  [`TDB-Plan__feature--${variant}`]: variant,
                })}
              >
                <Typography className="TDB-Plan__feature-title" fontSize="body" fontWeight="bold" color="neutral-800">
                  {feature.title}
                </Typography>
                {feature.features.map((f) => (
                  <FeatureContainer {...f} />
                ))}
              </div>
            ))}
          </div>
          <div className="TDB-Plan__hr" />
          <div className="TDB-Plan__footer-icon" />
        </div>
      </div>
    </div>
  </div>
);

export default Plan;
