import React from 'react';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import PageHeader from '@page-components/pricing/PageHeader';
import PricingPlan from '@page-components/pricing/PricingPlans/PricingPlan';
import Typography from '@components/Typography';
import PricingBanner from '@page-components/pricing/PricingBanner';
import './pricing.scss';

const PricingPage = () => {
  return (
    <IndexLayout
      pageName="pricing"
      helmet={{
        title: 'Pricing • TileDB',
        description:
          'Empowering individuals, universities, and organizations to manage complex data and run scalable compute operations with ease. Seamlessly organize users into teams and optimize workflows for any scale.',
      }}
    >
      <Header variant="transparent-white" />
      <main className="main TDB-Pricing">
        <PageHeader />
        <Typography
          className="TDB-Pricing__description"
          fontSize="subheading-3"
          fontWeight="medium"
          color="neutral-600"
          align="center"
          as="p"
        >
          Our new unit-based pricing makes it simple to purchase only the seats and resources you need, from startups and research
          institutes to mature enterprises.
        </Typography>
        <div className="TDB-Pricing__main">
          <PricingBanner />
          <PricingPlan />
        </div>
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default PricingPage;
