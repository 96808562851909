import * as React from 'react';
import Typography from '@components/Typography';
import pricingUnit from './assets/Pricing_Unit.png';
import pricingDse from './assets/Pricing_DSE.png';
import './PricingBanner.scss';

const PricingBanner: React.FC = () => {
  return (
    <div className="TDB-PricingBanner">
      <div className="TDB-PricingBanner__container">
        <div className="TDB-PricingBanner__aside">
          <img className="TDB-PricingBanner__icon" src={pricingUnit} alt="Pricing Unit" />
          <div className="TDB-PricingBanner__aside-body">
            <div className="TDB-PricingBanner__aside-text">
              <Typography className="TDB-PricingBanner__title" color="neutral-800">
                1 Unit
              </Typography>
              <Typography className="TDB-PricingBanner__units" fontSize="body" fontWeight="medium" color="neutral-600" as="p">
                Each unit is comprised of:
              </Typography>
              <Typography className="TDB-PricingBanner__units" fontSize="body" fontWeight="semi-bold" color="neutral-600">
                1 User Seat + 2 vCPUs
              </Typography>
            </div>
            <Typography as="p" className="TDB-PricingBanner__price" color="brand-800" fontSize="body" fontWeight="medium">
              <strong>$1000</strong> / unit per year
            </Typography>
          </div>
        </div>
        <div className="TDB-PricingBanner__vertical-line" />
        <div className="TDB-PricingBanner__main">
          <img className="TDB-PricingBanner__icon" src={pricingDse} alt="Dedicated Success Engineer" />
          <div className="TDB-PricingBanner__main-body">
            <div className="TDB-PricingBanner__main-text">
              <Typography className="TDB-PricingBanner__title" color="neutral-800">
                Dedicated Success Engineer
              </Typography>
              <Typography className="TDB-PricingBanner__description" fontSize="body" fontWeight="medium" color="neutral-600">
                A subject matter expert who works as an extension of your team expedites your success with TileDB.
              </Typography>
            </div>
            <div className="TDB-PricingBanner__main-info">
              <div className="TDB-PricingBanner__table">
                <div className="TDB-PricingBanner__tr">
                  <div className="TDB-PricingBanner__td TDB-PricingBanner__td--header">
                    <Typography color="brand-800" fontSize="body" fontWeight="bold">
                      Basic
                    </Typography>
                    <Typography className="TDB-PricingBanner__bg" color="brand-800" fontSize="body" fontWeight="semi-bold">
                      -
                    </Typography>
                  </div>

                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      Install services only
                    </Typography>
                  </div>
                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>$30,000</strong> / year
                    </Typography>
                  </div>
                </div>
                <div className="TDB-PricingBanner__tr">
                  <div className="TDB-PricingBanner__td TDB-PricingBanner__td--header">
                    <Typography color="brand-800" fontSize="body" fontWeight="bold">
                      Bronze
                    </Typography>
                    <Typography className="TDB-PricingBanner__bg" color="brand-800" fontSize="body" fontWeight="semi-bold">
                      1/4
                    </Typography>
                  </div>

                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>Up to 40 hours</strong> / month
                    </Typography>
                  </div>
                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>$150,000</strong> / year
                    </Typography>
                  </div>
                </div>
                <div className="TDB-PricingBanner__tr">
                  <div className="TDB-PricingBanner__td TDB-PricingBanner__td--header">
                    <Typography color="brand-800" fontSize="body" fontWeight="bold">
                      Silver
                    </Typography>
                    <Typography className="TDB-PricingBanner__bg" color="brand-800" fontSize="body" fontWeight="semi-bold">
                      1/2
                    </Typography>
                  </div>

                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>Up to 80 hours</strong> / month
                    </Typography>
                  </div>
                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>$275,000</strong> / year
                    </Typography>
                  </div>
                </div>
                <div className="TDB-PricingBanner__tr">
                  <div className="TDB-PricingBanner__td TDB-PricingBanner__td--header">
                    <Typography color="brand-800" fontSize="body" fontWeight="bold">
                      Gold
                    </Typography>
                    <Typography className="TDB-PricingBanner__bg" color="brand-800" fontSize="body" fontWeight="semi-bold">
                      Full
                    </Typography>
                  </div>

                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>Up to 160 hours</strong> / month
                    </Typography>
                  </div>
                  <div className="TDB-PricingBanner__td">
                    <Typography color="brand-800" fontSize="body" fontWeight="medium">
                      <strong>$500,000</strong> / year
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingBanner;
