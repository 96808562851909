import React from 'react';

const UnitsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3333 9.3335H10.6667C9.92999 9.3335 9.33333 9.93016 9.33333 10.6668V13.3335C9.33333 14.0668 9.92999 14.6635 10.6667 14.6668H13.3333C14.0667 14.6635 14.6633 14.0668 14.6667 13.3335V10.6668C14.6633 9.93016 14.0667 9.3335 13.3333 9.3335ZM13.3333 13.3335H10.6667V10.6635H13.3333V13.3335Z"
      fill="#018C9C"
    />
    <path
      d="M21.3333 11.3335H18V10.0002H20C20.3667 10.0002 20.6667 9.70018 20.6667 9.33352C20.6667 8.96352 20.3667 8.66685 20 8.66685H18V8.00018C17.9967 6.89352 17.1033 6.00018 16 6.00018H15.3333V4.00018C15.3333 3.63018 15.0333 3.33352 14.6667 3.33352C14.2967 3.33352 14 3.63018 14 4.00018V6.00018H12.6667V2.66685C12.6667 2.29685 12.3667 2.00018 12 2.00018C11.63 2.00018 11.3333 2.29685 11.3333 2.66685V6.00018H10V4.00018C10 3.63018 9.7 3.33352 9.33333 3.33352C8.96333 3.33352 8.66667 3.63018 8.66667 4.00018V6.00018H8C6.89333 6.00018 6 6.89352 6 8.00018V8.66685H4C3.63 8.66685 3.33333 8.96352 3.33333 9.33352C3.33333 9.70018 3.63 10.0002 4 10.0002H6V11.3335H2.66667C2.29667 11.3335 2 11.6302 2 12.0002C2 12.3668 2.29667 12.6668 2.66667 12.6668H6V14.0002H4C3.63 14.0002 3.33333 14.2968 3.33333 14.6668C3.33333 15.0335 3.63 15.3335 4 15.3335H6V16.0002C6 17.1035 6.89333 17.9965 8 17.9998H8.66667V19.9998C8.66667 20.3665 8.96333 20.6665 9.33333 20.6665C9.7 20.6665 10 20.3665 10 19.9998V17.9998H11.3333V21.3332C11.3333 21.6998 11.63 21.9998 12 21.9998C12.3667 21.9998 12.6667 21.6998 12.6667 21.3332V17.9998H14V19.9998C14 20.3665 14.2967 20.6665 14.6667 20.6665C15.0333 20.6665 15.3333 20.3665 15.3333 19.9998V17.9998H16C17.1033 17.9965 17.9967 17.1032 18 15.9998V15.3332H20C20.3667 15.3332 20.6667 15.0332 20.6667 14.6665C20.6667 14.2965 20.3667 13.9998 20 13.9998H18V12.6665H21.3333C21.7 12.6665 22 12.3665 22 11.9998C22 11.6298 21.7 11.3335 21.3333 11.3335ZM16.6667 16.0002C16.6633 16.3668 16.3667 16.6635 16 16.6668H8C7.63 16.6635 7.33333 16.3668 7.33333 16.0002V8.00018C7.33333 7.63018 7.63 7.33352 8 7.33352H16C16.3667 7.33352 16.6633 7.63018 16.6667 8.00018V16.0002Z"
      fill="#018C9C"
    />
  </svg>
);

export default UnitsIcon;
