import * as React from 'react';
import Plan from '../Plan';
import './PricingPlan.scss';

const PricingPlan: React.FC = () => {
  return (
    <>
      <div className="TDB-PricingPlan__main">
        <Plan
          title="Starter - $50k"
          description="Get started as economically as possible. "
          units="20 units"
          dse="Basic Dedicated Success Engineer"
          variant="secondary"
          features={[
            {
              title: '20 Units',
              features: [
                {
                  active: true,
                  text: '20 user seats',
                  key: 'sub-basic-1',
                },
                {
                  active: true,
                  text: '40 vCPUs',
                  key: 'sub-basic-2',
                },
              ],
            },
            {
              title: 'Basic Dedicated Success Engineer',
              features: [
                {
                  active: true,
                  text: 'Install',
                  key: 'sub-basic-3',
                },
                {
                  active: true,
                  text: 'Bug fixing',
                  key: 'sub-basic-4',
                },
                {
                  active: false,
                  text: 'Services',
                  key: 'sub-basic-5',
                },
                {
                  active: false,
                  text: 'SLAs',
                  key: 'sub-basic-6',
                },
              ],
            },
          ]}
          buttonText="Buy Starter Package"
        />
        <Plan
          title="Standard - $250k"
          description="This package is most frequently chosen by customers."
          units="100 units"
          dse="Bronze Dedicated Success Engineer"
          variant="primary"
          features={[
            {
              title: '100 Units',
              features: [
                {
                  active: true,
                  text: '100 user seats',
                  key: 'sub-basic-1',
                },
                {
                  active: true,
                  text: '200 vCPUs',
                  key: 'sub-basic-2',
                },
              ],
            },
            {
              title: 'Bronze Dedicated Success Engineer',
              features: [
                {
                  active: true,
                  text: 'Install',
                  key: 'sub-basic-3',
                },
                {
                  active: true,
                  text: 'Bug fixing',
                  key: 'sub-basic-4',
                },
                {
                  active: true,
                  text: 'Services – Up to 40 hours / month',
                  key: 'sub-basic-5',
                },
                {
                  active: true,
                  text: 'SLAs',
                  key: 'sub-basic-6',
                },
              ],
            },
          ]}
          buttonText="Buy Standard Package"
        />
        <Plan
          title="Custom"
          description="Tailor your combination of Units and DSE time."
          units="You choose: 20 - 1000+ units"
          dse="Custom Dedicated Success Engineer"
          variant="tertiary"
          features={[
            {
              title: '20 - 1000+ Units',
              features: [
                {
                  active: true,
                  text: '20 - 1000+ user seats',
                  key: 'sub-basic-1',
                },
                {
                  active: true,
                  text: '40 - 2000+ vCPUs',
                  key: 'sub-basic-2',
                },
              ],
            },
            {
              title: 'Custom Dedicated Success Engineer',
              features: [
                {
                  active: true,
                  text: 'Install',
                  key: 'sub-basic-3',
                },
                {
                  active: true,
                  text: 'Bug fixing',
                  key: 'sub-basic-4',
                },
                {
                  active: true,
                  text: 'Services',
                  key: 'sub-basic-5',
                },
                {
                  active: true,
                  text: 'SLAs',
                  description: '– are offered for customers using more than 100 units and at least a quarter-time capacity DSE',
                  key: 'sub-basic-6',
                },
              ],
            },
          ]}
          buttonText="Craft Your Custom Package"
        />
      </div>
    </>
  );
};

export default PricingPlan;
